<template>
    <div class="row justify-content-center row__width my-3">
         <swiper-container
            :navigation="{
                nextEl: '#nextSliderPacksboton',
                prevEl: '#prevSliderPacksboton'
            }"
            :pagination="{
                el: '#pagPackCourses',
                type: 'fraction',
                currentClass: 'paginationCurrentStyle',
                totalClass: 'paginationTotalStyle'
            }"
            :autoplay="true"
            :slides-per-view="1"
            :slides-per-group="1"
            :spaceBetween="20"
            :breakpoints="{
                375: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    autoplay: false,
                },
                768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    autoplay: false,
                },
                992: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                1875: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            }"
            @swiper="onSwiper"
            @mouseenter="stopAutoplay"
            @mouseleave="startAutoplay"
            class="mySwiper">
            <swiper-slide v-for="(product, id) in products_list" style="height: auto !important;" class="d-flex justify-content-center align-items-center">
                <ListadoCursosSlide
                    :id="id"
                    :rName="product.name"
                    :rSlug="product.slug"
                    :rOwnerSlug="product.professional.slug"
                    :rOwner="product.professional.name"
                    :rOwnerAvatar="product.professional.avatar"
                    :rImage="product.presentations[0]?.url"
                    :rModality="product.modality.translation.name"
                    :rDetail="product.details"
                    :rPrice="product.prices[0]"
                    :rReviews="product.reviews"
                    :rMarketplace="product.marketplace"
                    :rMembership="product.membership"
                    :rPurchases="product.purchases_count" :key="product"
                    :rProductsQuantity="product.products?.quantity"
                    :productsProducts="product.products?.data"
                />
            </swiper-slide>
        </swiper-container>
    </div>
</template>

<script setup>
import ListadoCursosSlide from '../Sliders/ListadoCursosSlide.vue'
import { useGeneralStore } from "../../stores/general"
import { useConfigStore } from "../../stores/config"
import { useUsersStore } from "../../stores/user" 
import { storeToRefs } from "pinia";
import { ref, watch, nextTick, inject, onMounted } from 'vue';
import { useI18n } from "vue-i18n";
import axios from 'axios';

const general = useGeneralStore();
const {filtrosMapa, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
const {} = general;

const config = useConfigStore();
const { coinSelected } = storeToRefs(config);

const users = useUsersStore()
const {  } = storeToRefs(users)
const { url } = users

const {locale} = useI18n({ useScope: 'global' })
const lang = ref()
const X = ref('')

const api_products = '/api/public/products/home'

const products_list = ref([])

const onSwiper = (swiper) => {
    console.log(swiper, 'swiper')
    swiper.pagination.render()
}

const stopAutoplay = (event) => {
  // Verificar si la pantalla es de escritorio
  if (window.innerWidth >= 992) { // 992px es un tamaño típico para pantallas de escritorio
    const swiperInstance = event.target.swiper;
    if (swiperInstance && swiperInstance.autoplay.running) {
      swiperInstance.autoplay.stop();
    }
  }
};

const startAutoplay = (event) => {
  // Verificar si la pantalla es de escritorio
  if (window.innerWidth >= 992) { // 992px es un tamaño típico para pantallas de escritorio
    const swiperInstance = event.target.swiper;
    if (swiperInstance && !swiperInstance.autoplay.running) {
      swiperInstance.autoplay.start();
    }
  }
};


const fetchProducts = async () => {
    const params = {
        classes: [3],
        quantity: 12
    };
    try {
        products_list.value = [];
        const response = await axios.get(`${url}${api_products}`, { params });
        console.log(response, 'response presets');
        products_list.value = response.data.data.data;
        console.log(products_list.value);
    } catch (error) {
        console.log(error);
    }
};

fetchProducts()
</script>

<style lang="scss" scoped>
.swiper{
    width: 100%;
    min-height: 100%;
    padding: 20px 5px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.mySwiper{
    padding: 20px 5px;
}
</style>