<template>
    <div class="row justify-content-center row__width" >
        <div class="col-12 d-flex justify-content-center align-items-center px-0">
            <div class="row flex-column justify-content-start align-items-start row__width mt-lg-4">
                <div class="col d-flex justify-content-start align-items-center">
                    <h2>{{titulo}}</h2>
                </div>
                <div class="col-12 d-flex justify-content-end align-items-center mt-2">
                    <router-link class="see_more" :to="url_results" >Ver todo ></router-link>
                </div>
                <div class="col d-flex justify-content-between justify-content-lg-center align-items-center px-0 pt-3" :class="filtrosDesplegados == true ? 'col-lg-12' : '' || mapaDesplegado == true ? 'col-lg-12' : '' ">
                    <div class="row justify-content-start align-items-center row__width position-relative" style="margin: auto;">
                        <!-- Buscador -->
                        <div class="col-12 col-lg-5 d-flex justify-content-center align-items-center contenedor px-0">
                            <input type="text" :placeholder="$t('message.placeholderSearchCoursesPacks')" @input="search_query != '' ? getSearchResults($event.target.value) : ''" @blur="hideSearchBox" v-model="search_query">
                            <button type="button" @click="router.push({name: 'ResultadosPacks', params: {search: search_query}})"><img src="../../img/home-icons/lupa.svg" alt=""></button>
                        </div>
                        <div class="search__box" v-show="search_box" @mousedown="keepVisible" id="div-busqueda">
                            <div class="row justify-content-center row__width focus">
                                <div class="col-12 d-flex justify-content-between align-items-center mb-2">
                                    <h4>Paquetes</h4>
                                    <router-link :to="{name: 'ResultadosPacks', params: {search: search_query}}"><h5 class="see-more">{{ $t('message.seeMore') }}</h5></router-link>
                                </div>
                                <div v-if="is_searching">
                                    <div class="col-12 d-flex justify-content-start align-items-center pb-4">
                                        <div class="skeleton-avatar"></div>
                                        <div class="skeleton-text"></div>
                                    </div>
                                    <div class="col-12 d-flex justify-content-start align-items-center pb-4">
                                        <div class="skeleton-avatar"></div>
                                        <div class="skeleton-text"></div>
                                    </div>
                                    <div class="col-12 d-flex justify-content-start align-items-center pb-4">
                                        <div class="skeleton-avatar"></div>
                                        <div class="skeleton-text"></div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-start align-items-center pb-4" v-else v-for="(pack, id) in search_results_list" v-if="search_results_list.length >= 1">
                                    <img :src="pack.image" alt="avatar" class="ejemplo" v-if="pack && pack.image">
                                    <router-link :to="{ name: 'HomeCursos', params: { slug: (pack.slugs.translations?.[lang]?.slug) || pack.dinamic_slug } }"><h5>{{ pack.names.translations?.[lang]?.name }}</h5></router-link>
                                </div>
                                <div class="col-12" v-if="!is_searching && search_results_list.length < 1">
                                    <h6 class="p-3">No hay coincidencias</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col col-sm-4 col-lg-2 d-flex justify-content-center align-items-center search-select ps-lg-3 pe-lg-0 my-3">
                            <MazSelect
                                class="row__width"
                                :placeholder="$t('message.categories')"
                                color="info"
                                :options="professions_list"
                                name="role"
                                search
                                search-placeholder="Buscar en la lista"
                                @update:model-value="router.push({name: 'ResultadosProfesores', params: {filters: 'profession'}})"
                            />
                        </div>
                        <!-- Fin Buscador -->
                        <div class="col-12 col-lg d-flex justify-content-end align-items-center contador__botones px-0 mx-0" :class="filtrosDesplegados == true ? 'col-lg-6 justify-content-end' : '' || mapaDesplegado == true ? 'col-lg-6 justify-content-end' : '' ">
                            <div class="row justify-content-end row__width">
                                <div class="col col__perso d-flex justify-content-end align-items-center">
                                    <div :id="pagination" class="paginationStyle"></div>
                                </div>
                                <div class="col col__perso d-flex justify-content-end align-items-center">
                                    <button :id="idBack"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                                    <button :id="idNext" class="me-0"><img src="../../img/home-icons/arrow-rigth-azul.svg" alt=""></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import MazSelect from 'maz-ui/components/MazSelect'
    import { useGeneralStore } from "../../stores/general"
    import { useUsersStore } from "../../stores/user"
    import { storeToRefs } from "pinia";
    import { ref, watch } from 'vue';
    import axios from 'axios';
    import { useRouter } from 'vue-router';

    const router = useRouter()
    
    const general = useGeneralStore()
    const {filtrosDesplegados, mapaDesplegado} = storeToRefs(general)
    const {} = general

    const users = useUsersStore()
    const { headers } = storeToRefs(users)
    const { url } = users

    const urlProfessions = '/api/public/professions'
    const meili_url = process.env.VUE_APP_MEILISEARCH_URL
    const meili_key = process.env.VUE_APP_MEILISEARCH_MASTER_KEY
    const urlApi = 'multi-search'

    const props = defineProps({
        titulo: '',
        idBack: '',
        idNext: '',
        pagination: '',
        url_results: '',
    })

    // Buscador

    const lang = ref('ES')

    const search_query = ref('')
    const search_box = ref(false)
    const search_results_list = ref([])
    const is_searching = ref(false)
    const professions_list = ref([])

    const search = ref({
        queries:[{
            indexUid: 'product_products',
            filter: `class.translations.${lang.value}.name = "Paquetes"`,
            q:'',
            limit: 3
        }]
    })

    const showSearchBox = () => {
        search_box.value = true
    }

    const hideSearchBox = () => {
        search_box.value = false
    }

    const keepVisible = (event) => {
      event.preventDefault()
    }

    watch(search_query, (newValue) => {
        if (newValue === '') {
            hideSearchBox()
        } else {
            showSearchBox()
        }
    })

    const getSearchResults =  async () => {
        search.value.queries[0].q = search_query.value
        search_results_list.value = []
        console.log(search.value.queries)
        if (!is_searching.value) {
            is_searching.value = true
            try {
                await axios.post(meili_url+urlApi, search.value, {headers: {'Authorization': `Bearer ${meili_key}`}}).then((result) => {
                    console.log(result)
                    const packs = result.data.results.find(x => x.indexUid == 'product_products')
                    console.log(packs)
                    search_results_list.value = packs.hits
                    console.log(search_results_list.value)
                })
            }
            catch (error) {
                console.log(error)
            }
            is_searching.value = false
        }
    }

    const fetchProfessions = async () => {
        try {
            const response = await axios.get(`${url}${urlProfessions}`, {headers: headers.value})
            console.log(response)
            professions_list.value = response.data.data
            professions_list.value.forEach((prof) => {
                prof.label = prof.translation.name,
                prof.value = prof.id
            })
            console.log(professions_list.value)
        } catch (error) {
            console.log(error)
        }
    }
    fetchProfessions()

    // Fin Buscador

</script>

<style lang="scss" scoped>
h2{
    font-size: 30px;
    font-weight: 700;
    color: var(--colorSecondary);
    text-align: left;
}
.contenedor{
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 40px;
    height: 60px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    @media (min-width: 992px) {
        border-radius: 40px;
    }
    @media (max-width: 991px) {
        width: 100%;
    }
    input{
        border: 0;
        color: #B8C1DB;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
        padding-left: 15px;
        &::placeholder{
            color: #B8C1DB;
        }
        &:focus{
            outline: 0;
        }
        @media (min-width: 992px) {
            color: #697891;
            font-size: 16px;
            width: 93%;
        }
    }
    button{
        position: absolute;
        right: 7px;
        border: 0;
        min-height: 45px;
        width: 45px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        background-color: var(--colorPrimary);
        justify-content: center;
        align-items: center;
        img{
            padding: 0;
            max-height: 22px;
        }
        @media (min-width: 992px) {
            right: 10px;
        }
    }
}
select{
    border: 0;
    font-weight: 600;
    font-size: 16px;
    color: var(--colorSecondary);
    -webkit-appearance: none !important;
    height: 50px;
    width: 100%;
    &:focus{
        outline: 0;
        box-shadow: none;
    }
    
}
.contador__botones{
    p, h3{
        font-weight: 700;
        font-size: 18px;
        color: var(--colorSecondary);
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 3px;
    }
    h3{
        font-weight: 400;
        padding-left: 3px;
    }
    button{
        background-color: var(--bgPrincipal);
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #F5F5F5;
        border-radius: 21px;
        width: 42px;
        height: 42px;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.col__perso{
    max-width: max-content;
}
.paginationStyle {
    font-weight: 700!important;
    font-size: 18px!important;
    color: var(--colorSecondary)!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    padding-right: 3px!important;
    span {
        font-weight: 400!important;
        padding-left: 3px!important;
    }
}

.search__box{
    position: absolute;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 30px;
    background-color: var(--bgPrincipal);
    height: auto;
    width: 90vw;
    max-width: 460px;
    z-index: 99;
    padding-top: 20px;
    padding-left: 30px;
    justify-content: center;
    align-items: flex-start;
    transition: all ease 1s;
    top: 70px;
    img{
        border-radius: 50%;
        height: 40px;
        width: 40px;
        object-fit: cover;
    }
    a{
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    .see-more{
        color: var(--colorPrimary);
    }
    h4{
        color: var(--colorSecondary);
        font-size: 24px;
        font-weight: 700;
        padding-left: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (min-width: 992px) {
            font-weight: 500;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    h5{
        font-weight: 500;
        font-size: 16px;
        color: #111316;
        padding-left: 10px;
        text-align: left;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    h6{
        font-weight: 500;
        font-size: 14px;
        color: #697891;
        padding-left: 5px;
        text-align: left;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
}
.skeleton-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e0e0e0;
    margin-right: 10px;
}

.skeleton-text {
    width: 150px;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 4px;
}

.skeleton-avatar,
.skeleton-text {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}

.see_more{
    font-size: 14px;
    color: var(--colorPrimary);
    font-weight: 600;
}
</style>