<template>
    <div class="row justify-content-center my-3 row__width">
        <div class="col-11 d-flex flex-column justify-content-start align-items-center slide px-0">
            <router-link :to="'home-cursos/'+slug" class="col-12 d-flex justify-content-center align-items-center px-3 my-2 position-relative" >
                <img :src="rImage" alt="" class="ejemplo" >
                <!-- <div class="row justify-content-center row__width position">
                    <div class="col-12 d-flex justify-content-center align-items-center px-1" style="padding: 0 0;">
                        <div class="row justify-content-center row__width row__slide">
                            <div class="col-12 d-flex justify-content-between align-items-start arriba">
                                <img src="../../img/home-icons/cursos-icons/verificado.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div> -->
            </router-link>
            <router-link :to="'/perfil-usuario/'+ownerSlug" class="row justify-content-center descripcion row__width">
                <div class="col-12 d-flex justify-content-start align-items-center creador">
                    <img :src="avatar" alt="" class="ejemplo">
                    <div class="d-flex flex-column justify-content-center align-items-start ps-2">
                        <h3>{{$t('message.creator')}}</h3>
                        <h4>{{ owner }}</h4>
                    </div>
                </div>
            </router-link>
            <router-link :to="'home-cursos/'+slug" class="row justify-content-center  row__width">
                <div class="col-12 d-flex flex-column justify-content-center align-items-start descripcion">
                    <h2>{{ name }}</h2>
                    <!-- <h5>En este manual te eneñaremos más de 300 recetas de repostería!</h5> -->
                    <span class="border-bottom mt-4 w-100"></span>
                </div>
                <div class="col-12 col-lg d-flex flex-column justify-content-center align-items-start padding pe-0 comprar my-3" v-if="price">
                    <h2>{{ price.coin_type[0].abbr }} ${{ price.finalPrice }}</h2>
                    <h5>{{ price.coin_type[0].abbr }} ${{ price.totalPrice }}</h5>
                </div>
                <div class="col-12 col-lg-7 d-flex justify-content-center align-items-center mt-2 ps-lg-0 comprar" >
                    <BTNPrimario :url="'home-cursos/'" :slug="slug" :textoBTNPrimario="$t('message.buy')"/>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script setup>
import BTNPrimario from "../Botones/PrimarioRutas.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";
import { useGeneralStore } from "../../stores/general"
import { storeToRefs } from "pinia";
import { ref, watch } from 'vue';
import { useI18n } from "vue-i18n";
import axios from 'axios';

const general = useGeneralStore();
const {textos, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
const {} = general;

const {locale} = useI18n({ useScope: 'global' })
const lang = ref()
const X = ref('')

const props = defineProps({
        rName: '',
        rSlug: '',
        rOwner: '',
        rOwnerAvatar: '',
        rImage: '',
        rModality: '',
        rDetail: '',
        rPrice: '',
        rVerified: '',
        rProductVerified: '',
        rCreatedAt: '',
        rCollabs: '',
        rReviews: '',
        rMarketplace: '',
        rMembership: '',
        rPurchases: '',
        id: '',
        rOwnerSlug: '',
    })

    //Setea las variables con los resultados para su uso
    let id = props.id
    let name = props.rName
    let slug = props.rSlug
    let owner = props.rOwner
    let ownerSlug = props.rOwnerSlug
    let avatar = props.rOwnerAvatar
    let image = props.rImage
    let modality = props.rModality
    let details = props.rDetail
    let price = props.rPrice
    let verified = props.rVerified
    let productVerified = props.rProductVerified
    let createdAt = props.rCreatedAt
    let collabs = props.rCollabs
    let reviews = props.rReviews
    let marketplace = props.rMarketplace
    let membership = props.rMembership
    let purchases_count = props.rPurchases
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 0px;
    margin: 0px 0;
}
.slide{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    height: 550px;
    max-height: 550px;
    .ejemplo{
        width: 100%;
        border-radius: 15px;
        object-fit: cover;
        height: 240px;
    }
    .position{
        position: absolute;
        top: 0;
        min-height: 100%;
        width: 100%;
        z-index: 3;
        h6{
            font-family: 'Proxima Nova';
            font-weight: 500;
            font-size: 17px;
            color: #FEB230;
        }
        p{  font-family: 'Proxima Nova';
            font-weight: 500;
            font-size: 17px;
            color: var(--bgPrincipal);
            padding-left: 3px;
        }
        .row__slide{
            position: relative;
            min-height: 100% !important;
            min-height: 250px;
            width: 100%;
            .arriba{
                position: absolute;
                top: 10%;
            }
            .abajo{
                position: absolute;
                bottom: 10%;
            }
        }
        
    }
    .filtro{
        &::before{
            content: '';
            position: absolute;
            bottom: 10px;
            width: 96%;
            height: 40%;
            border-radius: 0px 0px 15px 15px;
            background: linear-gradient(to top,  #081026 , #252E4800);
            filter: opacity(1);
            z-index: 2;
        }
    }
    .descripcion{
        h2{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.2;
            text-align: left;
        }
        h3{
            color: #B8C1DB;
            font-weight: 500;
            font-size: 15px;
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                margin-left: 5px;
            }
        }
        h5{
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            padding-top: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.2;
            text-align: left;

        }
        p{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        .creador{
            border-right: 1px solid rgba(184, 193, 219, 0.3);
            padding-bottom: 10px;
            .ejemplo{
                height: 50px;
                width: 50px;
                object-fit: cover;
                border-radius: 50%;
            }
        }
        .border-bottom{
            width: 90%;
            height: 1px;
            background-color: rgba(184, 193, 219, 0.3);
        }
    }
    .comprar{
        margin-bottom: 5px;
        margin-top: 15px;
        h2{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }
        h5{
            font-size: 14px;
            font-weight: 400;
            color: #B8C1DB;
            text-decoration: line-through;
            text-align: left;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }
        .padding{
            padding-left: 10px;
        }
    }
}
</style>