<template>
    <div class="row justify-content-center align-items-center row__width position-relative mtlg">
        <div class="col-12  d-flex justify-content-start align-items-center px-0 d-none d-lg-flex position" :class="ocultar == true ? 'd-lg-none' : ''">
            <button class="btn_none me-3 px-0">
                <img src="../../img/roles-icons/arrow-left-negro.svg" alt="">
            </button>
            <h6>{{$t('message.return')}}</h6>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-center">
            <img src="../../img/Logo.png" alt="">
        </div>
    </div>
</template>

<script setup>
    import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";

    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;

    const props = defineProps({
        ocultar:{
            type: Boolean,
            default: false
        }
    })
</script>

<style lang="scss" scoped>
h2{
    font-size: 38px;
    font-weight: 700;
    color: var(--colorPrimary);
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        font-size: 38px;
        font-weight: 700;
        color: var(--colorSecondary);
    }
    @media (min-width: 992px) {
        font-size: 30px;
        p{
            font-size: 30px;
        }
    }
}
h6{
    color: #1F1F39;
    font-size: 16px;
    font-weight: 700;
}
@media (min-width: 992px) {
    .position{
        position: absolute;
        left: 0;
    }
    .mtlg{
        margin-top: 5%;
    }
}

img{
    width: 200px;
}
</style>